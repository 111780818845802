<script lang="ts" setup>
import type { HomeBanners } from "@/types";

defineProps<{ banner: HomeBanners[number] }>();

const { openFlipTheCoin } = useFlipTheCoin();
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="openFlipTheCoin"
	>
		<template #default>
			<AText class="banner-title" type="base big-md" :modifiers="['uppercase', 'italic', 'medium']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText type="h4 h2-md">
				<p v-html="banner.smallTitle" />
			</AText>
		</template>

		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn">
				{{ banner.buttonText }}
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped></style>
